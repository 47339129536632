body {
  /* background-color: #171717; */
  background-color: #07030d;
  background-image: url("../public/purplewall.png");
  background-size: cover;
  /* background-blend-mode: color-dodge; */

}

.App {
  color: #ffffff;
  width: 100%;
  height: 100%
}

a {
  color: #ffffff;
  text-decoration: none;
}

@font-face {
  font-family: Neuva;
  src: url("../public/Neuva.ttf");
}

@font-face {
  font-family: neon;
  src: url("../public/NeoncityScript-K77Je.otf");
}

@font-face {
  font-family: ParadisoB;
  src: url("../public/Paradiso Bold.ttf");
}


#aboutP1 {
  color: #eaeaeae5;
  font-family: "Comfortaa";
  font-size: 1.4em;
  text-align: center;
  max-width: 29em;
}

.aboutborder {
  border: 0.1em solid #c7d5ff;
  border-radius: 3em;
  max-width: 24em;
  box-shadow: 0 0 .2rem #c7d5ff,
    0 0 .2rem #c7d5ff,
    0 0 2rem #1386fe,
    0 0 0.8rem #1386fe,
    0 0 2.8rem #1386fe,
    inset 0 0 1.3rem #1386fe;
}

.intro {
  font-family: Neuva;
  font-size: 10em;
}

#hi {
  color: #d9d7ef
}

#name {
  color: #a09ae7;
  text-align: center;
  font-family: 'Sacramento', sans-serif;
  font-size: 14em;
  font-weight: 400;
  color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #bc13fe,
    0 0 82px #bc13fe,
    0 0 92px #bc13fe,
    0 0 102px #bc13fe,
    0 0 151px #bc13fe;
  animation: pulsate 0.11s ease-in-out infinite alternate;
}

#firstn {
  animation: turnon 3s;
}

@keyframes turnon {

  0%,
  5% {
    opacity: 10%;
    text-shadow: none;
  }

  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    opacity: 100%;
    text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #f09,
      0 0 80px #f09,
      0 0 90px #f09,
      0 0 100px #f09,
      0 0 150px #f09;
  }

  20%,
  24%,
  55% {
    opacity: 10%;
    text-shadow: none;
  }
}

#lastn {
  animation: turnon2 1.5s;
}

@keyframes turnon2 {

  0%,
  5% {
    opacity: 10%;
    text-shadow: none;
  }

  15%,
  79%,
  84%,
  100% {
    opacity: 100%;
    text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #f09,
      0 0 80px #f09,
      0 0 90px #f09,
      0 0 100px #f09,
      0 0 150px #f09;
  }


  81% {
    opacity: 10%;
    text-shadow: none;
  }
}

@keyframes pulsate {

  100% {
    text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #f09,
      0 0 80px #f09,
      0 0 90px #f09,
      0 0 100px #f09,
      0 0 150px #f09;

  }

  0% {
    text-shadow:
      0 0 4px #fff,
      0 0 10px #fff,
      0 0 18px #fff,
      0 0 38px #f09,
      0 0 73px #f09,
      0 0 80px #f09,
      0 0 94px #f09,
      0 0 140px #f09;

  }

}

.links {
  font-family: ParadisoB;
  font-size: 1.3em;
  text-align: center;
  border: 0.2em solid #e5b0ff;
  border-radius: 15px;
  padding: 0.6em;
  box-shadow: 0 0 .2rem #e5b0ff,
    0 0 .2rem #e5b0ff,
    0 0 1.5rem #bc13fe,
    0 0 0.5rem #bc13fe,
    0 0 2.5rem #bc13fe,
    inset 0 0 1.1rem #bc13fe;
}

.bubble {
  color: #a9a9a9;
}

.bubble:hover {
  color: white;
  /* text-shadow: 
  0 0 7px #fff,
  0 0 10px #fff,
  0 0 21px #fff,
  0 0 42px #bc13fe,
  0 0 82px #bc13fe,
  0 0 92px #bc13fe,
  0 0 102px #bc13fe,
  0 0 151px #bc13fe; */

}

@keyframes drop {
  0% {
    transform: translateY(-200px) scaleY(0.9);
    opacity: 0;
  }

  5% {
    opacity: .7;
  }

  50% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }

  65% {
    transform: translateY(-17px) scaleY(.9);
    opacity: 1;
  }

  75% {
    transform: translateY(-22px) scaleY(.9);
    opacity: 1;
  }

  100% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
}

#sticker {
  background-image: url("../public/stickerflipsmall.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 30em;
  width: 130%;
  position: relative;
  z-index: 1000;
}

#hammenu {
  display: none
}

@media only screen and (max-width: 600px) {
  #hammenu {
    display: inline;
  }

  #name {
    animation-play-state: paused;
    font-size: 7em;
    margin-bottom: 1em;
    margin-top: .8em;
    display: block;
  }

  #firstn {
    margin-right: 0;
    animation: turnon 2s;
  }

  .intro {
    font-size: 5em;
    padding: 1rem;
  }

  #hi {
    height: 5rem;
  }

  #aboutP1 {
    font-size: 1em;
    max-width: 20em;
  }

  .aboutborder {
    max-width: 15em;
  }

  #desktoplinks {
    display: none;
  }

  .links {
    font-family: ParadisoB;
    font-size: 1.7em;
    text-align: center;
    border: none;
    border-radius: 15px;
    padding-top: 4em;
    box-shadow: none;
    text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #bc13fe,
      0 0 82px #bc13fe,
      0 0 92px #bc13fe,
      0 0 102px #bc13fe,
      0 0 151px #bc13fe;
    letter-spacing: .3em;
  }

  .bubble {
    color: white;
  }

  /* .burger-wrapper {
    cursor: pointer;
  } */
  #sidebar {

    position: fixed;
    background-color: #07030d;
    background-image: url("../public/purplewall.png");
    background-size: cover;
    /* background-blend-mode: color-dodge; */
    height: 100vh;
    width: 100%;
    transform: translateX(50rem);
    top: 0;

    transition: 0.9s;
    right: 0;
    z-index: 0;
  }

  #burger-click {
    display: none;
  }

  #burger-click:checked~#sidebar {
    transform: translateX(0rem);
  }

  #burger {
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;
    right: 16px;
    top: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  #burger>div {
    height: 2px;
    background-color: #000;
    transition: 0.5s;
    z-index: 999;
  }

  #burger-click:checked~#burger>div {
    opacity: 0;

  }

  #burger-click:not(:checked)~#burger>div {
    background-color: #e5b0ff;
    opacity: 1;
    box-shadow: 0 0 .2rem #e5b0ff,
      0 0 .2rem #e5b0ff,
      0 0 1.5rem #bc13fe,
      0 0 0.5rem #bc13fe,
      0 0 2.5rem #bc13fe,
      inset 0 0 1.1rem #bc13fe;
  }

  #burger-click:checked~#burger>.burger-line {
    opacity: 0;
  }

  #burger-click:checked~#burger>.close-button {
    opacity: 1;
  }

  #burger-click:not(:checked)~#burger>.close-button {
    opacity: 0;
  }


  .close-button span {
    font-size: 50px;
    position: absolute;
    top: -23px;
    left: 3px;
    color: #d466ff;
    text-shadow: 0 0 2px #e6a5ff,
      0 0 8px #e6a5ff,
      0 0 21px #e6a5ff,
      0 0 42px #bc13fe,
      0 0 82px #bc13fe,
      0 0 92px #bc13fe,
      0 0 102px #bc13fe,
      0 0 151px #bc13fe;
    ;
  }

  #burger-click~#burger>.close-button {
    opacity: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #name {
    font-size: 10em;
  }
}